import {Injectable} from '@angular/core';
//import {filter, Observable, Subject} from "rxjs";
//import {Alert, AlertOptions, AlertType} from "../models/alert";
//import {HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {RestApiService} from "@makeo-packages/mkongtools";
import {NotificationsService} from "./notifications.service";
import {SwPush} from "@angular/service-worker";

// TODO : use service worker
// https://angular.dev/ecosystem/service-workers/push-notifications

@Injectable({
  providedIn: 'root'
})
export class NtfyService {

  protected userWantPushNotification : boolean = false;
  protected subscribeTopic : string = "";
  protected subscribeURL : string = "";
  protected eventSource : any = null;
  protected events : [any];

  public constructor(
    private api: RestApiService,
    private notificationsService: NotificationsService,
    private swPush: SwPush
  ){
    this.subscribeTopic = "topic-identifier-rvzkop852"; // NTFY_PUBLISH_DEFAULT_TOPIC
    this.subscribeURL = "https://ntfy." + environment.domain + "/" + this.subscribeTopic + "/sse";
    console.log("subscribeURL : ", this.subscribeURL);
    // subscribeURL : https://ntfy.espaceclient-parismedium-release.staging.makeo.fr/topic-identifier-rvzkop852/sse
    //              : https://ntfy.espaceclient-parismedium-release.staging.makeo.fr/topic-identifier-rvzkop852/sse

    if (environment.production) { // prod, preprod, release, hotfix
      this.eventSource = new EventSource(this.subscribeURL, {
        withCredentials: false
      });
      // this.sendNotification("Hi there!"); //test
      this.initializeV3();
    }else{
      console.log("disable ntfy event source (https required)");
    }

  }

  public initializeV3(){
    this.webPushSubscription(true).then(r => {
      console.log('~~~~ webPushSubscription OK ~~~~');
    });
  }

  public initialize(){

    // TODO : request to get user preference or retrieve user object with this info
    let userNotificationPreferences = {
      push : true,
      email : true,
      sms : false
    }
    this.userWantPushNotification = userNotificationPreferences.push ? userNotificationPreferences.push : false;

    let promise = null;
    if(this.userWantPushNotification){
      let promise = Notification.requestPermission();
      if (!("Notification" in window)) {
        // Check if the browser supports notifications
        alert("This browser does not support desktop notification");
      }
      else {
       promise.then((permission) => {
         if (permission === 'granted') {
           console.log('Notification permission granted.');
           this.subscribeToNotifications();
         } else {
           console.log('Unable to get permission to notify.');
         }
       });
      }
    }
    return promise; // wait for permission
  }

  private subscribeToNotifications() {
    if (this.swPush.isEnabled) {
      this.swPush.requestSubscription({
        //serverPublicKey: environment.VAPID_PUBLIC_KEY
        serverPublicKey: 'test1'
      })
        .then(sub => {
          // Envoyez l'abonnement à votre serveur backend
          this.api.post('/api/notifications/subscribe', sub).subscribe();
        })
        .catch(err => console.error('Erreur d\'abonnement:', err));
    }
  }

  public listenEvents(){
    // Incoming events
    this.eventSource.onopen = () => {
      console.log("EventSource connected to " + this.subscribeURL);
    };
    this.eventSource.onerror = (e) => {
      console.log("EventSource error: Failed to connect to " + this.subscribeURL);
    };
    this.eventSource.onmessage = (e) => {
      console.log("Event received, data : ", e.data);
      this.events.push(e.data);
      this.processEvent(e.data);
    };
  }

  public processEvent(eventData) :void{
    console.log('processEvent() eventData:', eventData);
    // (If in app, show notification to user ?)
    // Else app service worker receive it in background and send to host (Android/iOS)
    switch(eventData.event){
      case "keepalive" : break;
      case "message" : this.showNotification(eventData.message); break;
      default : console.log('warning : event de type ' + eventData.event + ' non géré.');
    }
  }

  public showNotification(text : string ) {
    if (Notification.permission === "granted") {
      const notification = new Notification(text);
    } else {
      console.log('No permission to notify.');
    }
  }


  // /www/ntfy/web/src/app/Notifier.js
  // ou https://github.com/binwiederhier/ntfy/issues/346
  public testV2(){
    if (navigator.serviceWorker){

      // service-worker registration (snippet below)
      // navigator.serviceWorker.register('service-worker.js');

      // ready event
      navigator.serviceWorker.ready
        .then(function(registration){
          return registration.pushManager.getSubscription()
            .then(function(subscription){
              if (subscription)
                // already subscribed
                return subscription;

              // new subscription
              // public key from the backend
              return fetch("api/push/BItQO0OxrqL5qiViTph78Coyv-JEr7jfBtT7y11rEnlacgLh-AQ60eY2MUzifhsIZE6gEZYUvSh6BoeSGkcxrL0")
                .then(function(res){ return res.text(); })
                .then(function(vapidPublicKey){
                  return registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: vapidPublicKey
                  });
                });
            });
        })
        .then(function(subscription){
          // TODO: store subscription object in the backend for later notification
        });

    } else {
      //no push
    }
  }

  //------------------------------------------- START CODE FROM NTFY REPOSITORY (PWA APP, /www/ntfy/web/src/app/Notifier.js) ---------------------------------------------
  // requiert :
  // - config
  //      config.web_push_public_key
  //      config.enable_web_push
  //
  // - urlB64ToUint8Array
  public urlB64ToUint8Array(base64String){
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; i += 1) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };
  public config = {
    web_push_public_key : 'BItQO0OxrqL5qiViTph78Coyv-JEr7jfBtT7y11rEnlacgLh-AQ60eY2MUzifhsIZE6gEZYUvSh6BoeSGkcxrL0',
    enable_web_push : true,
  };

  async webPushSubscription(hasWebPushTopics) {
    const pushManager = await this.pushManager();
    const existingSubscription = await pushManager.getSubscription();
    if (existingSubscription) {
      return existingSubscription;
    }

    // Create a new subscription only if there are new topics to subscribe to. It is possible that Web Push
    // was previously enabled and then disabled again in which case there would be an existingSubscription.
    // If, however, it was _not_ enabled previously, we create a new subscription if it is now enabled.

    if (hasWebPushTopics) {
      return pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlB64ToUint8Array(this.config.web_push_public_key),         // ICI ON SOUSCRIT !
      });
    }

    return undefined;
  }

  async pushManager() {
    return (await this.serviceWorkerRegistration()).pushManager;
  }

  async serviceWorkerRegistration() {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      throw new Error("No service worker registration found");
    }
    return registration;
  }

  notRequested() {
    return this.supported() && Notification.permission === "default";
  }

  granted() {
    return this.supported() && Notification.permission === "granted";
  }

  denied() {
    return this.supported() && Notification.permission === "denied";
  }

  async maybeRequestPermission() {
    if (!this.supported()) {
      return false;
    }

    return new Promise((resolve) => {
      Notification.requestPermission((permission) => {
        resolve(permission === "granted");
      });
    });
  }

  supported() {
    return this.browserSupported() && this.contextSupported();
  }

  browserSupported() {
    return "Notification" in window;
  }

  pushSupported() {
    return this.config.enable_web_push && "serviceWorker" in navigator && "PushManager" in window;
  }

  pushPossible() {
    return this.pushSupported() && this.contextSupported() && this.granted() && !this.iosSupportedButInstallRequired();
  }

  /**
   * Returns true if this is a HTTPS site, or served over localhost. Otherwise the Notification API
   * is not supported, see https://developer.mozilla.org/en-US/docs/Web/API/notification
   */
  contextSupported() {
    return window.location.protocol === "https:" || window.location.hostname.match("^127.") || window.location.hostname === "localhost";
  }

  // no PushManager when not installed, but it _is_ supported.
  iosSupportedButInstallRequired() {

    // @ts-ignore
    return (
      this.config.enable_web_push &&
      // a service worker exists
      "serviceWorker" in navigator &&
      // but the pushmanager API is missing, which implies we're on an iOS device without installing
      !("PushManager" in window) &&
      // check that this is the case by checking for `standalone`, which only exists on Safari
      // window.navigator.standalone === false
      ((window as Window)?.navigator?.standalone === false)

    );
  }
  //-------------------------------------------- END CODE FROM NTFY REPOSITORY (PWA APP, /www/ntfy/web/src/app/Notifier.js)  ---------------------------------------------



}
