import {DestroyRef, inject, Injectable} from '@angular/core';

import {AppLocalStorageService, RestApiService} from "@makeo-packages/mkongtools";
import {User} from "../models/user";
import {map, Observable, Observer} from "rxjs";
import {Router} from "@angular/router";
import {AuthService, UserService as AbstractUserService} from "@makeo-packages/mkongusers"


@Injectable({providedIn: 'root'})
export class AuthUserService extends AbstractUserService {

    public userData : any;
    // (private User est inclu via extends AbstractUserService)

    private api: RestApiService
    apiEndpoint = 'user';

    public accessToken?: string;

    private myAuthService: AuthService;
    private router: any;

    private nonAuthRoutes = [
        '/connexion',
        '/auth/login',
        '/inscription',
    ];

    constructor(apiService: RestApiService, localStorage: AppLocalStorageService, authService: AuthService, router : Router) {
        super(apiService, localStorage, authService);
        this.api = apiService
        this.userSubject.subscribe({
            next :(value)=>{
                this.userData = value;
                //this.userRight = this.userData?.user_type?.user_right;
            }
        })
        this.myAuthService = authService;
        this.router = router;
    }

    public login(datas) {
        return this.api.post('login_check', datas)
    }

    signIn(email: string, password: string) {
        //alert("[auth-user.service] call signIn()"); // OK
        return this.myAuthService.signIn({ username: email, password: password });
    }

    signOut() {
        //alert("[auth-user.service] call signOut()"); // OK
        let r = this.myAuthService.signOut();

        //-- necessaire sinon bug mkongusers
        this.myAuthService.accessToken = '';
        this.router.navigate(['/']);
        //--

        return r;
    }

    setAccessToken(token: string|null|undefined) {
        this.myAuthService.accessToken = token;
    }

    getAccessToken() {
        return this.myAuthService.accessToken;
    }

    inArrayStartsWith(needle, array) {
        var length = array.length;
        for(var i = 0; i < length; i++) {
            if(needle.startsWith(array[i])) return true;
        }
        return false;
    }


    userInfos() {
        // on evite d'appeler api/user/infos à l'affichage d'une page no auth avec le menu (exemple ecran de connexion/inscription)
        if(this.inArrayStartsWith(this.router.url, this.nonAuthRoutes)){
            console.log('return empty user infos because nonAuthRoutes');
            return new Observable((observer: Observer<any>) => {
                observer.next(null)
                observer.complete()
            })
        }
        // si l'objet user est deja charge en front, on l'utilise :
        if (this.userData) {
            return new Observable((observer: Observer<any>) => {
                observer.next(this.userData)
                observer.complete()
            })
        }
        // sinon on fait une requete au back end et on rempli userData :
        return this.api.get("user/infos").pipe(map((response: any) => {
            this.userData = response.datas as User;
            return this.userData;
        }));
    }

}
