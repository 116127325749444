//https://gitlab.staging.makeo.fr/makeo-packages/mkongusers/-/blob/develop/projects/mkongusers/src/lib/interceptors/auth.interceptor.ts

import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, finalize, Observable, ReplaySubject, switchMap, throwError} from 'rxjs';

import {Router} from "@angular/router";
import {AuthService} from "@makeo-packages/mkongusers";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private nonAuthRoutes = [
    /^.*\/login_check$/g
  ];
  private nonAuthFrontRoute = [
    /^.*\/connexion$/g,
    /^.*\/inscription#.*$/g
  ]

  tokenSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  private isRefreshingToken: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    // /!\ erreur EmptyErrorImpl si on essaye de faire passer dans le contructeur :
    //   private authService: AuthService,   avec    import {AuthService} from "../services/auth.service"
    //   ou
    //   private authService: AuthCustomService,    avec class AuthCustomService extends AuthService de @makeo-packages/mkongusers
    // ... authService doit etre celui de from "@makeo-packages/mkongusers"
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any> = this.createRequestWithToken(request);
    return next.handle(newReq).pipe(
      catchError((err: HttpErrorResponse) => {
        let message = this.extractErrorMessage(err);
        if(typeof message === 'undefined'){
          console.log('HTTP catch error undefined message on err=', err);
        }
        else{
          // alert("intercept() message:" + message);
          if (message.includes("Jeton authentification non 2FA_OK")) {
            this.logout();
          }
          // correction page blanche chargement si token expiré
          if (message.includes("Expired JWT Token")) {
            if (!this.isRefreshingToken) {
              this.isRefreshingToken = true;
              console.log("jwt token expired, try to refresh token.");
              // @ts-ignore
              return this.authService.newToken().pipe(switchMap((token: any) => {
                  if (token) {
                    console.log("refresh token : OK");
                    this.tokenSubject.next(token.token);
                    if (token.token != null) {
                      return next.handle(request.clone({setHeaders: {Authorization: 'Bearer ' + token.token}}));
                    } else {
                      return next.handle(request.clone());
                    }
                  }
                  window.location.reload();
                }),
                catchError(error => {
                  // Si la generation du nouveau token a echoué, on redirige vers l'écran de connexion
                  console.log("refresh token : FAIL"); // exemple : trop vieux
                  console.log(error);
                  let regExps = this.nonAuthFrontRoute.filter(a => a.test(this.router.url));
                  if (regExps.length == 0) {
                    setTimeout(() => {
                      this.router.navigate(['connexion'])
                      this.authService.signOut()
                    }, 1000);
                  }
                  throwError(error);
                  return next.handle(request.clone());
                }),
                finalize(() => {
                  this.isRefreshingToken = false;
                }));
            }
          }
          throw err;
        }
      })
    );
  }

  private logout()
  {
    setTimeout(() => {
      this.router.navigate(['connexion'])
      this.authService.signOut()
    }, 1000);
  }

  private extractErrorMessage(error: HttpErrorResponse) : string {
    let message = error.error.message;

    return message;
  }

  private createRequestWithToken(request: HttpRequest<any>) {
    if (this.authService.accessToken != null) {
      return  request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.authService.accessToken)
      });
    } else {
      return  request.clone();
    }
  }

}


