import { Injectable } from '@angular/core';
import {RestApiService} from "@makeo-packages/mkongtools";
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public apiEndpoint: string = 'notifications';

  constructor(protected apiService: RestApiService) {
  }

 // public getAllNotifications(): Observable<any> {
    //return this.apiService.get(this.apiEndpoint);
 // }

  public getNotificationsByClientId(id: number): Observable<any> {
    return this.apiService.get(`${this.apiEndpoint}`, id);
  }

  public updateNotificationsByClientId(id: number, data): Observable<any> {
    const url = `${this.apiEndpoint}/notificationLabel/${id}`;
    return this.apiService.post(url, { data });
  }


}
